@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;500;600;700&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --color-131921: #00488d;
  --color-3b4149: #00488d;
  --color-febd69: #febd69;
  --color-232f3e: #232f3e;
  --color-bf4800: #bf4800;
  --color-f5f5f7: #f5f5f7;
  --color-ededed: #ededed;
  --color-777777: #777777;
  --color-1c1c1b: #1c1c1b;
}
.section-heading {
  font-size: 26px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 30px;
}
.card-wrapper {
  background-color: white;
  box-shadow: 0 0 10px #0000001a;
  padding: 15px;
}
body {
  font-family: 'Montserrat', sans-serif;
}
.main-banner.position-relative img {
  height: 540px;
  object-fit: cover;
  width: 100%;
}
.sp-testimonial-free:before {
  background: url(https://globalimagingusa.com/wp-content/uploads/2022/06/testtop.jpg);
  background-repeat: no-repeat;
  width: 28px;
  height: 28px;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 20px !important;
  content: "";
  display: block;
  padding-bottom: 60px;
  background-repeat: no-repeat;
}

.ysm-search-widget.ysm-search-widget-1 .search-field[type="search"] {
  background: url(https://globalimagingusa.com/wp-content/uploads/2022/06/search.png);
  background-repeat: no-repeat;
  background-position: 0px 13px;
  padding-left: 25px;
  padding-top: 7px;
  padding-bottom: 7px;
  z-index: 1005;
  border-color: #333;
    color: #000;
    border: 0;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    z-index: 1005;
}
[type=search] {
  outline: none;
}
a {
  text-decoration: none;
  display: inline-block;
}
.form-control:focus {
  box-shadow: none;
  border-color: inherit;
}
.breadcrumb p {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
}
.breadcrumb {
  border-top: 1px solid #e3e3e3;
  padding-top: 50px !important;
  padding-bottom: 0 !important;
}
.gap-10 {
  gap: 10px;
}
.gap-15 {
  gap: 15px;
}
.gap-30 {
  gap: 30px;
}
section.home-wrapper-1 .container-xxl {
  max-width: 100%;
}
.button {
  background-color: transparent;
  color: #000;
  font-size: 14px;
  padding: 13px 33px;
  border-radius: 0px;
  border: 2px solid #00bbea;
}
.button:hover {
  background-color: #00bbea;
  color: #131921;
}
/* Header Part */

.header-top-strip {
  border-bottom: 1px solid var(--color-3b4149);
  background-color: var(--color-131921);
}
.header-upper {
  background-color: #fff;
}
.header-bottom {
  background-color: var(--color-232f3e);
}
.banner-bottom {
  background: #16488D;
  padding: 10px;
  width: 98.5%;
  margin: 0px auto;
}
.banner-bottom h2 {
  font-size: 34px;
  color: #F6F6F6;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: center;
}
.header-upper .input-group-text {
  background-color: var(--color-febd69);
}
.header-upper.header-upper-links img {
  width: 35px;
  height: 35px;
}
.header-bottom .menu-links a,
.header-bottom .dropdown button {
  color: white;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: 0.3;
  text-transform: uppercase;
}
.header-top-strip a {
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
}
.header-menu a{
  text-transform: uppercase;
  color: #454545;
  font-size: 13px;
}
.header-cart img {
  width: 18px;
}
header.header-top-strip {
  padding: 10px 0;
}
.header-cart span.badge {
  font-size: 9px;
  border-radius: 20px;
  margin-bottom: -25px;
  padding: 4px 5px;
  position: absolute;
  top: -10px;
  right: -7px;
  background-color: #ff3333;
}
.header-cart {
  position: relative;
}
.header-bottom .dropdown button:focus {
  box-shadow: none;
}
.header-bottom .dropdown-menu {
  background-color: var(--color-131921);
  width: 100%;
  transform: translate3d(0px, 57px, 0px) !important;
}
.header-bottom .dropdown-item {
  padding: 20px;
  margin-bottom: 3px;
  border-bottom: 1px solid var(--color-3b4149);
}
.header-bottom .dropdown-item:hover {
  background-color: transparent;
  color: var(--color-febd69) !important;
}
.counter h6 {
  color: #00488d;
  font-size: 41px;
  text-align: center;
  text-transform: uppercase;
}
.featured-right.d-flex.flex-wrap.gap.align-items-center {
  column-gap: 10px;
}
.featured-right.d-flex.gap.align-items-center .col-4 {
  margin-right: 10px;
}

.featured-right.d-flex.gap.align-items-center .col-4:nth-child(3n) {
  margin-right: 0;
}
.main-banner-content.featured-left {
  width: 100%;
}
.main-banner-content.featured-left p {
  font-size: 17px;
  text-align: left;
  font-weight: 400;
}
section.featured-wrap.find-by-parts {
  max-width: 82%;
  margin: 10px auto;
}
.main-banner-content a.button {
  color: #000;
}
.featured-wrap.find-by-parts-first .featured-right.d-flex.gap.align-items-center {
  column-gap: 10px;
}
section.featured-wrap.find-by-parts .featured-right.d-flex.gap.align-items-center {
  column-gap: 10px;
}
.counter p {
  color: #00488d;
  font-size: 26px;
  text-align: center;
  font-weight: 400;
  text-transform: uppercase;
}
section.featured-wrap {
  margin-bottom: 10px;
}
/* Header Part End Here */

/* Footer Part */

footer {
  background-color: #284E98;
}
.footer-link.footer-address.d-flex.flex-column{
  margin-top: 53px;
}
.footer-address {
  margin-top: 43px;
}
footer:not(:first-child) {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
footer .input-group-text {
  background-color: #284E98;
  color: white;
  padding: 8px 17px;
}
.col-4.contact-col h4 {
  font-size: 42px;
  color: #72c0ea;
  text-transform: uppercase;
  font-weight: 400;
}
.col-4.contact-col h4 span{
  font-weight: 700;
}
footer.py-5.copyright {
  background: #6cb5de;
}
address.text-white.fs-6 p {
  margin: 0;
}
.footer-menu a:before {
  content: "|";
  display: inline-block;
  color: #fff;
  margin-right: 10px;
  font-size: 16px;
}
.footer-link.footer-menu a {
  font-size: 15px;
  font-weight: 400;
}
.footer-menu a.first-child:before{
display: none;
}
.menu-links.header-menu {
  padding-left: 50px;
}
/* Footer Part Ends Here */

/* Home Page */
.main-banner-content {
  top: 20%;
  left: 7%;
  width: 41%;
}
.main-banner-content h4 {
  font-size: 48px;
  font-weight: 400;
  line-height: 54px;
  color: #00488d;
  margin: 0 0px;
  text-transform: uppercase;
}
.main-banner-content h5 {
  font-size: 48px;
  line-height: 54px;
  color: #00488d;
  font-weight: 700;
  text-transform: none;
}
.main-banner-content.featured-left.byparts h4 {
  text-align: center;
}

.main-banner-content.featured-left.byparts h4 span {
  font-weight: bold;
}

.main-banner-content.featured-left.byparts p {
  text-align: center;
  margin-top: 10px;
}
.featured-wrap .featured-right.d-flex.gap.align-items-center {
  padding: 0 !important;
}
.main-banner-content p {
  font-size: 22px;
  line-height: 30px;
  color: #504e4a;
  margin: 30px 0;
  font-weight: 400;
}
.small-banner-content {
  top: 25%;
  left: 10%;
}
.small-banner-content h4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-bf4800);
  margin: 0 0 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}
.small-banner-content h5 {
  font-size: 22px;
  line-height: 34px;
  letter-spacing: -2px;
  font-weight: 500;
  text-transform: none;
}

.small-banner-content p {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.4;
  color: var(--color-131921);
}
.small-banner {
  width: 49%;
}

/* home wrapper 2 */
.home-wrapper-2 {
  background-color: #fff;
}
.sp-testimonial-free {
  background: #eff0f0;
  padding: 15px;
}
.sp-testimonial-client-testimonial p {
  padding: 5px 15px;
  font-size: 15px !important;
  min-height: 370px;
  text-align: center;
}
.sp-testimonial-free-section .sp-testimonial-title {
  color: #333333;
}
h3.sp-testimonial-post-title {
  font-size: 16px !important;
  text-align: center;
  text-transform: capitalize;
}
section.testimonial-wrapper.home-wrapper-22 {
  margin-bottom: 130px;
}
/* home wrapper 2 ends */

/* categories section */

.categories {
  background-color: white;
  box-shadow: 0 0 10px #0000001a;
  padding: 15px;
}
.categories > div {
  width: 25%;
  padding: 10px 10px 10px 20px;
}
.categories > div > div {
  width: 60%;
}
.categories > div:nth-child(1),
.categories > div:nth-child(2),
.categories > div:nth-child(3),
.categories > div:nth-child(4) {
  border-bottom: 1px solid var(--color-ededed);
}
.categories > div:nth-child(1),
.categories > div:nth-child(2),
.categories > div:nth-child(3) {
  border-right: 1px solid var(--color-ededed);
}
.categories > div:nth-child(5),
.categories > div:nth-child(6),
.categories > div:nth-child(7) {
  border-right: 1px solid var(--color-ededed);
}
/* categories section ends here */
/* Home Page Ends */

/* BLog Part */

.blog-card {
  background-color: white;

  border-radius: 10px;
}
.blog-card img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.blog-content {
  padding: 15px;
}
.main-banner-content.featured-left.byparts.trusted-brands span {
  display: block;
}
.collection-grids.row {
  max-width: 85%;
  text-align: center;
  margin: 0px auto;
  row-gap: 20px;
}
section.blog-wrapper.py-5.home-wrapper-2 {
  background: #fff;
}
.blog-contentp.date {
  font-size: 13px;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--color-777777);
  padding: 0;
}
a.brand-url {
  border: 1px solid #eee;
  padding: 15px 30px;
}
a.button.add-to-cart {
  background: #32a13c;
  padding: 6px 24px;
  border-radius: 0;
  color: #fff;
  border: 2px solid #32a13c;
  font-size: 14px;
  display: block;
  width: 150px;
  margin: auto;
  text-transform: uppercase;
}
.blog-content h5 {
  font-size: 18px;
  color: var(--color-131921);
  font-weight: 400;
}
p.date {
  color: #adadad;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}
.blog-content p.desc {
  font-size: 13px;
  line-height: 22px;
  color: var(--color-777777);
}
.single-blog-card a {
  font-size: 18px;
  color: var(--color-777777);
  margin-bottom: 20px;
}
.single-blog-card h3 {
  font-size: 24px;
  font-weight: 600;
  color: var(--color-1c1c1b);
}
.single-blog-card p {
  color: var(--color-777777);
  font-size: 14px;
}
/*  Blog Part ends here */

/* Product Card */

.product-card {
  padding: 15px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  text-align: center;
}
.product-card .product-image {
  height: 270px;
}
.product-card .product-image img {
  object-fit: contain;
}
.product-card .product-image > img:last-child {
  display: none;
}
.product-card:hover .product-image > img:nth-child(1) {
  display: none;
}
.product-card:hover .product-image > img:last-child {
  display: block;
  text-align: center;
  margin: 0px auto;
}
.product-card:hover .action-bar {
  right: 15px;
}
.product-card .product-details h6 {
  color: var(--color-bf4800);
  font-size: 13px;
}
.product-card .product-details h5 {
  font-size: 15px;
  color: var(--color-1c1c1b);
}
.product-card .product-details p.price {
  font-size: 16px;
  color: var(--color-1c1c1b);
}
.product-card p.description {
  font-size: 13px;
  color: var(--color-777777);
  margin-right: 20px;
}
.action-bar {
  top: 10%;
  right: -23px;
  transition: 0.3s;
}
.featured-right.d-flex.gap.align-items-center {
  padding: 10px 40px;
}
.wishlist-icon {
  top: 2%;
  right: 15px;
}
.product-image-wrap h5.product-title {
  font-size: 17px;
  line-height: 26px;
  font-weight: 400 !important;
  text-align: center;
  padding: 0 15px;
  min-height: 78px;
  margin: 0;
  color: #504e4a;
}
.product-image-wrap {
  background: #fff;
  display: block;
  border: 1px solid #ccc;
  padding: 15px;
  margin-bottom: 15px;
}
/* Product Card ends here */

/* Special Product */

.special-product-card {
  padding: 20px 10px;
  border-radius: 10px;
  background-color: white;
}

/* Special Product ends here */

/* famous card */
section.featured-wrapper.find-area.py-5.home-wrapper-2 .featured-right.d-flex.gap.align-items-center {
  justify-content: flex-end;
  padding-left: 25px;
}
.famous-card .famous-content {
  top: 43%;
  left: 27%;
}
.famous-card img {
  border-radius: 0px;
}
a.button.featured-button{
  background: rgba(37, 71, 141, 0.9);
  color: #fff;
  width: 100%;
  text-align: center;
  padding: 15px;
  font-size: 16px;
  line-height: 22px;
  border: none;
}
a.button.featured-button:hover {
  background: #32a13ca8;
}
.famous-content h5 {
  font-size: 13px;
  line-height: 20px;
  font-weight: 400px;
  color: white;
  margin-bottom: 7px;
  text-transform: uppercase;
}
.famous-content h6 {
  font-size: 24px;
  line-height: 38px;
  font-weight: 500;
  color: white;
}
.famous-content p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 100;
  color: white;
}
/* famous card ends */

/* Our Store */
.filter-card {
  background-color: white;
  border-radius: 10px;
  padding: 15px 15px 25px;
}
.filter-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-bottom: 20px;
}
.filter-card ul,
.filter-card .form-check-label {
  list-style-type: none;
  font-size: 13px;
  line-height: 30px;
  color: var(--color-777777);
  cursor: pointer;
}
.filter-card .form-check-input:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.25);
}
.filter-card .form-check-input:checked {
  background-color: var(--color-febd69);
  border-color: var(--color-febd69);
}
.sub-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-bottom: 15px;
  margin-top: 20px;
}
.colors {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.colors li {
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
}
.random-products h5 {
  font-size: 14px;
  margin-bottom: 8px;
}
.random-products:first-child {
  border-bottom: 1px solid var(--color-ededed);
}
.filter-sort-grid {
  padding: 10px;
  background-color: white;
  border-radius: 10px;
}
.grid img {
  width: 35px;
  height: 35px;
  background-color: #f2f2f2;
  padding: 8px;
  border-radius: 3px;
  cursor: pointer;
}
.gr-12 {
  width: 100%;
}
.gr-6 {
  width: 49%;
}
.gr-4 {
  width: 32%;
}
.gr-3 {
  width: 24%;
}
.gr-12 .product-card {
  display: flex;
  gap: 20px;
}
.gr-6 .product-image {
  width: 100%;
}
.gr-6 .product-image img {
  margin: auto;
  display: block;
}
.gr-12 .product-card .product-image {
  width: 270px;
}
.gr-12 .product-card .product-image {
  height: auto;
}
.gr-12 .action-bar {
  top: 17%;
}
/* Our store ends here */

/* Contact Page */
.contact-inner-wrapper {
  padding: 30px 20px;
  border-radius: 15px;
  background-color: white;
  gap: 15px;
}
.contact-inner-wrapper > div {
  width: 48%;
}
.contact-title {
  font-size: 26px;
  font-weight: 500;
  line-height: 35px;
  text-align: left;
}
.contact-inner-wrapper form .form-control {
  background-color: var(--color-f5f5f7);
  border-radius: 10px;
  border-color: transparent;
  padding: 10px;
}
.contact-inner-wrapper ul {
  list-style: none;
}
.contact-inner-wrapper address,
.contact-inner-wrapper a,
.contact-inner-wrapper p {
  color: var(--color-777777);
}
/* Contact Page ends */

/* Compare Product Page */

.compare-product-card {
  padding: 20px 15px;
  background-color: white;
  border-radius: 10px;
}
.compare-product-card .cross,
.wishlist-card .cross {
  top: 15px;
  right: 15px;
  width: 25px;
  padding: 3px;
  cursor: pointer;
}
.compare-product-details h5.title,
.wishlist-card h5.title {
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  color: var(--color-1c1c1b);
}
.compare-product-details h6.price,
.wishlist-card h6.price {
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
}
.compare-product-card .product-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
/* Compare Product Page end here */

/*  auth card */

.auth-card {
  padding: 20px;
  background-color: white;
  width: 500px;
  margin: 30px auto;
  border-radius: 10px;
}
.auth-card h3 {
  font-size: 19px;
  font-weight: 500;
  color: var(--color-777777);
}
.auth-card p {
  font-size: 13px;
  color: var(--color-777777);
}
.auth-card .form-control {
  height: 50px;
  background-color: var(--color-f5f5f7);
  color: var(--color-777777);
  font-size: 14px;
  border-radius: 10px;
  border-color: transparent;
}
.auth-card a {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-1c1c1b);
}
.signup {
  background-color: var(--color-febd69);
  color: var(--color-131921);
  border: none;
}
.signup:hover {
  background-color: var(--color-131921);
  color: white;
}
/*  auth card  ends*/

/* Policy Pages */

.policy {
  background-color: white;
  padding: 30px;
}
/* Policy Pages  ends */

/* Main Product Page */

.description-wrapper h4,
.reviews-wrapper h3 {
  font-size: 26px;
  color: #1c1c1b;
  margin-bottom: 10px;
}
.description-wrapper p {
  font-size: 14px;
  color: var(--color-777777);
}

.review-inner-wrapper {
  background-color: white;
  padding: 30px;
}
.review-head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
}
.review-head h4 {
  font-size: 18px;
  color: var(--color-1c1c1b);
}
.review-head p,
.review-head a {
  font-size: 14px;
  color: var(--color-777777);
}
.review-form h4 {
  font-size: 16px;
  color: var(--color-777777);
}
.review-form {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.review:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.review p {
  font-size: 14px;
  color: var(--color-777777);
}

.main-product-image {
  padding: 0px;
  background-color: white;
  border-radius: 10px;
}
.main-product-image > div {
  border: 1px solid rgba(0, 0, 0, 0.25);
}
.main-product-image img {
  width: 100% !important;
  height: 600px !important;
  object-fit: cover;
}
.other-product-images {
  background-color: white;
  padding: 20px 0;
}
.other-product-images > div {
  padding: 0px;
  width: 23%;
  border: 1px solid rgba(0, 0, 0, 0.25);
}
.main-product-image .js-image-zoom__zoomed-image {
  left: 50px !important;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  z-index: 1000;
}
.main-product-details {
  padding: 0px 20px;
  background-color: white;
  border-radius: 10px;
}
.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}
.main-product-details h3.title {
  font-size: 36px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-bottom: 10px;
}
.main-product-details p.price {
  font-size: 23px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #00488c;
}
.main-product-details p.price span {
  color: #000;
  margin-right: 10px;;
}
.main-product-details a.review-btn,
.t-review {
  color: var(--color-777777);
  font-size: 13px;
}
.product-heading {
  font-size: 14px;
  color: var(--color-1c1c1b);
  margin-bottom: 0px;
  text-transform: uppercase;
  font-weight: 600;
}
.product-data {
  font-size: 13px;
  columns: var(--color-777777);
  margin-bottom: 0px;
  text-transform: uppercase;
}
.main-product-details a {
  font-size: 14px;
  color: var(--color-777777);
}
/* Main Product Page */

/* Cart Page */
.cart-header,
.cart-data {
  border-bottom: 1px solid #eaeaea;
}
.cart-header h4 {
  font-size: 13px;
  color: var(--color-777777);
}
.cart-col-1 {
  width: 40%;
}
.cart-col-1 p {
  color: var(--color-777777);
  font-size: 14px;
}
.cart-col-2 {
  width: 10%;
}
.cart-col-3 {
  width: 15%;
}
.cart-col-4 {
  width: 15%;
}
.cart-data h5.price {
  font-size: 14px;
  color: var(--color-1c1c1b);
}
/* Cart Page Ends here */

/* Shippping Page */

.total {
  font-size: 14px;
  color: var(--color-1c1c1b);
}
.total-price {
  font-size: 16px;
  color: var(--color-777777);
}
/* Shipping page ends here */
